const PDFEx = ({
  src,
  width,
  height,
}: {
  src: string;
  width?: string | number;
  height?: string | number;
}) => {
  return <iframe src={src} height={height} width={width} />;
};

export default PDFEx;
