import { useEffect, useRef } from 'react';

import { isTabActive } from '../App';
import { UpdaterSettingsDefault } from '../helpers/settings';

export function useUpdater(
  callback: () => void,
  delay = UpdaterSettingsDefault.generalUpdaterTime,
  straight = true,
) {
  const savedCallback = useRef<any>();

  useEffect(() => {
    straight && callback();

    const handleVisibilityChange = () => {
      document.visibilityState === 'visible' && savedCallback?.current();
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      isTabActive && savedCallback?.current();
    }
    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}
