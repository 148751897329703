import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import styled from 'styled-components/macro';

import { t } from '../../config/i18n';
import { notify, roundFloat } from '../../helpers/utils';
import { CurrencyStore } from '../../store';
import { getCryptoRates, getSubRate } from '../../utils/api';
import { Rate } from '../../utils/types';
import { Defaults, Hr, px, Spacer, TextEx } from '../common';
import ActionButton from '../controls/ActionButton';
import { IconChecked, IconUnchecked } from '../controls/CheckboxEx';
import CurrenciesSelect from '../controls/CurrenciesSelect';
import { DialogTextLine, MessageBox } from '../controls/Dialog';
import InputEx from '../controls/InputEx';
import {
  AmountInput,
  pageBorderColor,
  PageCaption,
  PageContent,
  pageFontSize,
  pageInfoCaptionColor,
  PageReadOnlyValue,
  PageRoot,
} from '../controls/Page';
import QuantityInput from '../controls/QuantityInput';
import SwitchEx from '../controls/SwitchEx';
import Txt from '../controls/Txt';
import { currency } from '../currencies';

const controlHeight = '3.75rem';

const validAmount = {
  BTC: 0.00001,
  ETH: 0.001,
  USDT: 1,
};

export const PromocodeCreate = observer(
  ({
    width,
    onCreate,
  }: {
    width: any;
    onCreate: (
      crypto,
      currency: string,
      amount,
      isAmountCurrency: boolean,
      count: number,
      cb: (id: string) => void,
    ) => void;
  }) => {
    const { fiatCurrency, getBalances } = CurrencyStore;
    const [crypto, setCrypto] = React.useState<string>('');
    const [cryptoRates, setCryptoRates] = React.useState<Rate[]>([]);
    const [amount, setAmount] = React.useState<number>(0);
    const [, setQuantity] = React.useState<number>(0);
    const [openQuantity, setOpenQuantity] = React.useState<boolean>(false);
    const [promocode, setPromocode] = React.useState<string>('');
    const [openSuccess, setOpenSuccess] = React.useState<boolean>(false);
    const [inCrypto, setInCrypto] = React.useState<boolean>(false);

    const getCryptos = (): string[] => cryptoRates.map((rate) => rate.currency);

    const setPromocodeQuantity = async (quantity: number) => {
      setQuantity(quantity);
      setOpenQuantity(false);

      onCreate(crypto, fiatCurrency, amount, !inCrypto, quantity, (p) => {
        setPromocode(p);
        setOpenSuccess(true);
        getBalances();
      });
    };

    const validateAmountBeforeCreate = () => {
      if (inCrypto && amount < validAmount[crypto]) {
        notify(t('promocodes.minimal-value') + validAmount[crypto], 'info');
      }
    };

    if (cryptoRates.length === 0) {
      getCryptoRates().then((rates) => {
        setCryptoRates(rates);
        if (rates.length > 0) {
          setCrypto(rates[0].currency);
        }
      });
    }

    return (
      <PageRoot className={'promocode-block'} width={width} minWidth={'7.06rem'}>
        <PageCaption title={'promocodes.create-promocode'} />
        <Hr />
        <PageContent>
          <TextEx size={'1rem'} color={pageInfoCaptionColor}>
            <Txt k={'promocodes.create-promocode-hint'} />
          </TextEx>
          <ControlLabel
            caption={'promocodes.create-in-crypto'}
            grow={1}
            alignSelf={'flex-start'}
            direction={'row'}>
            <SwitchEx
              checked={inCrypto}
              onChange={() => setInCrypto((inCrypto) => !inCrypto)}
              swapColors
            />
          </ControlLabel>
          <Controls className={'ControlsWrap'}>
            <ControlLabel caption={'promocodes.currency'}>
              <CurrenciesSelect
                className={'crypto-select'}
                id={crypto}
                onChange={setCrypto}
                currencyLeft={'-.3rem'}
                restriction={getCryptos()}
                height={controlHeight}
                borderColor={pageBorderColor}
              />
            </ControlLabel>
            <ControlLabel caption={'promocodes.amount'} grow={1}>
              <AmountInput
                value={amount}
                onChange={setAmount}
                crypto={inCrypto ? crypto : undefined}
                onBlur={validateAmountBeforeCreate}
                height={controlHeight}
                imgWidth={!inCrypto ? controlHeight : undefined}
                img={!inCrypto ? <CurrencyImg currencyName={fiatCurrency} /> : undefined}
              />
            </ControlLabel>
            <ButtonBox className={'btn-box'} width={controlHeight}>
              <ActionButton
                minWidth={controlHeight}
                disabled={(inCrypto && amount < validAmount[crypto]) || !amount}
                height={controlHeight}
                onClick={() => setOpenQuantity(true)}>
                <ArrowRightIcon size={'1rem'} fill={'white'} />
              </ActionButton>
            </ButtonBox>
          </Controls>
        </PageContent>
        <PromocodesCreateQuantity
          open={openQuantity}
          onCancel={() => setOpenQuantity(false)}
          onCreate={setPromocodeQuantity}
        />
        <PromocodeCreateSuccess
          open={openSuccess}
          promocode={promocode}
          onClose={() => setOpenSuccess(false)}
        />
      </PageRoot>
    );
  },
);

export const PromocodeActivate = ({
  width,
  onActivate,
  busy,
}: {
  width: any;
  onActivate: (promocode: string, success: () => void) => void;
  busy: boolean;
}) => {
  const { getBalances } = CurrencyStore;
  const [promocode, setPromocode] = React.useState<string>('');
  const [activateError, setActivateError] = React.useState<string>('');
  const [openResult, setOpenResult] = React.useState<boolean>(false);

  const activate = () =>
    onActivate(promocode, () => {
      getBalances();
      setActivateError('');
      setOpenResult(true);
    });

  return (
    <PageRoot className={'promocode-block'} width={width} minWidth={'31.875rem'}>
      <PageCaption title={'promocodes.activate-promocode'} />
      <Hr />
      <PageContent>
        <TextEx size={'1rem'} color={pageInfoCaptionColor}>
          <Txt k={'promocodes.activate-promocode-hint'} />
        </TextEx>
        <Controls>
          <ControlLabel caption={'promocodes.enter-promocode'}>
            <InputEx
              type={'text'}
              value={promocode}
              onChange={setPromocode}
              minWidth={'3.75rem'}
              height={controlHeight}
              borderColor={pageBorderColor}
              fontSize={pageFontSize}
              hint={'XXXX'}
            />
          </ControlLabel>
          <ButtonBox width={controlHeight}>
            {promocode ? (
              <ActionButton
                minWidth={controlHeight}
                height={controlHeight}
                busy={busy}
                onClick={() => activate()}>
                <OkIcon size={'1rem'} fill={'white'} />
              </ActionButton>
            ) : undefined}
          </ButtonBox>
        </Controls>
      </PageContent>
      <PromocodeActivateResult
        open={openResult}
        error={activateError}
        onClose={() => setOpenResult(false)}
      />
    </PageRoot>
  );
};

const PromocodesCreateQuantity = ({
  open,
  onCreate,
  onCancel,
}: {
  open: boolean;
  onCreate: (quantity: number) => void;
  onCancel: () => void;
}) => {
  const [quantity, setQuantity] = React.useState<number>(1);

  useEffect(() => {
    setQuantity(1);
  }, [open]);

  return (
    <MessageBox
      open={open}
      caption={'promocodes.create-promocode'}
      closeCaption={'promocodes.create-promocode'}
      onClose={() => onCreate(quantity)}
      onCancel={onCancel}>
      <PromocodeQuantityBox>
        <TextEx size={pageFontSize}>
          <Txt k={'promocodes.quantity'} />
        </TextEx>
        <Spacer width={'3.75rem'} />
        <QuantityInput
          min={1}
          value={quantity}
          preventZero={true}
          onChange={setQuantity}
          width={200}
          height={controlHeight}
        />
      </PromocodeQuantityBox>
    </MessageBox>
  );
};

const PromocodeCreateSuccess = ({
  open,
  promocode,
  onClose,
}: {
  open: boolean;
  promocode: string;
  onClose: () => void;
}) => (
  <MessageBox open={open} onClose={onClose}>
    <CloudIcon success={true} size={'4.625rem'} />
    <DialogTextLine size={pageFontSize}>
      <Txt k={'promocodes.create-success'} />
    </DialogTextLine>
    <Spacer height={'1vh'} />
    <PageReadOnlyValue width={'7.06rem'} height={controlHeight} textAlign={'center'} copy>
      {promocode}
    </PageReadOnlyValue>
  </MessageBox>
);

const PromocodeActivateResult = ({
  open,
  error,
  onClose,
}: {
  open: boolean;
  error: string;
  onClose: () => void;
}) => (
  <MessageBox open={open} onClose={onClose}>
    <CloudIcon success={error === ''} size={'4.625rem'} />
    <DialogTextLine size={pageFontSize}>
      <Txt k={`promocodes.activate-${error === '' ? 'success' : 'error'}`} />
    </DialogTextLine>
    {error !== '' ? (
      <DialogTextLine size={pageFontSize} top={'1vh'}>
        {error}
      </DialogTextLine>
    ) : undefined}
  </MessageBox>
);

const ControlLabel = ({
  caption,
  grow,
  children,
  alignSelf,
  direction,
}: {
  caption: string;
  grow?: number;
  children: React.ReactNode;
  alignSelf?: string;
  direction?: 'column' | 'row';
}) => (
  <ControlBox grow={grow} alignSelf={alignSelf} direction={direction}>
    <TextEx size={pageFontSize}>
      <Txt k={caption} />:
    </TextEx>
    <div className={'control-box'}>{children}</div>
  </ControlBox>
);

const CurrencyImg = ({
  currencyName,
  onClick,
}: {
  currencyName: string;
  onClick?: () => void;
}) => (
  <CurrencyImgBox onClick={onClick}>
    {currency(currencyName, pageFontSize, pageInfoCaptionColor)}
  </CurrencyImgBox>
);

export const ArrowRightIcon = ({
  size,
  fill,
}: {
  size: number | string;
  fill?: string;
}) => (
  <svg style={{ width: size, height: size }} viewBox={'0 0 268 268'} fill={fill}>
    <g>
      <path
        d="M265.171,125.577l-80-80c-4.881-4.881-12.797-4.881-17.678,0c-4.882,4.882-4.882,12.796,0,
                17.678l58.661,58.661H12.5 c-6.903,0-12.5,5.597-12.5,12.5c0,6.902,5.597,12.5,12.5,12.5h213.654l-58.659,
                58.661c-4.882,4.882-4.882,12.796,0,17.678 c2.44,2.439,5.64,3.661,8.839,3.661s6.398-1.222,
                8.839-3.661l79.998-80C270.053,138.373,270.053,130.459,265.171,125.577z"
      />
    </g>
  </svg>
);

const OkIcon = ({ size, fill }: { size: number | string; fill?: string }) => (
  <svg style={{ width: size, height: size }} viewBox={'0 0 26 26'} fill={fill}>
    <path
      d="m.3,14c-0.2-0.2-0.3-0.5-0.3-0.7s0.1-0.5 0.3-0.7l1.4-1.4c0.4-0.4 1-0.4 1.4,0l.1,.1 5.5,5.9c0.2,0.2 0.5,
            0.2 0.7,0l13.4-13.9h0.1v-8.88178e-16c0.4-0.4 1-0.4 1.4,0l1.4,1.4c0.4,0.4 0.4,1 0,1.4l0,0-16,16.6c-0.2,
            0.2-0.4,0.3-0.7,0.3-0.3,0-0.5-0.1-0.7-0.3l-7.8-8.4-.2-.3z"
    />
  </svg>
);

const CloudIcon = ({ success, size, top }: { success: boolean; size; top?: number }) => {
  const resultSize = roundFloat(size * 0.25, 0);
  return (
    <CloudIconBox top={top} checkedOffset={(size - resultSize) / 1.9}>
      <svg style={{ width: size, height: size }} viewBox={'0 0 60 60'}>
        <g>
          <path
            fill={'#e4e4e4'}
            d="M50.976,26.194C50.447,17.194,43.028,10,34.085,10c-5.43,0-10.688,2.663-13.946,
            7.008c-0.075-0.039-0.154-0.066-0.23-0.102 c-0.198-0.096-0.399-0.187-0.604-0.269c-0.114-0.045-0.228-0.086-0.343-0.126c-0.203-0.071-0.409-0.134-0.619-0.191
	        c-0.115-0.031-0.229-0.063-0.345-0.089c-0.225-0.051-0.455-0.09-0.687-0.125c-0.101-0.015-0.2-0.035-0.302-0.046
	        C16.677,16.023,16.341,16,16,16c-4.963,0-9,4.037-9,9c0,0.127,0.008,0.252,0.016,0.377v0.004C2.857,27.649,0,32.399,0,37.154
	        C0,44.237,5.762,50,12.845,50h24.508c0.104,0,0.207-0.006,0.311-0.014l0.062-0.008l0.134,0.008C37.962,49.994,38.064,50,38.169,50
	        h9.803C54.604,50,60,44.604,60,37.972C60,32.483,56.173,27.56,50.976,26.194z M47.972,48h-9.803c-0.059,0-0.116-0.005-0.174-0.009
	        l-0.271-0.011l-0.198,0.011C37.469,47.995,37.411,48,37.353,48H12.845C6.865,48,2,43.135,2,37.154C2,33,4.705,28.688,8.433,26.901
	        L9,26.63V26c0-0.127,0.008-0.256,0.015-0.386l0.009-0.16l-0.012-0.21C9.006,25.163,9,25.082,9,25c0-3.859,3.141-7,7-7
	        c0.309,0,0.614,0.027,0.917,0.067c0.078,0.01,0.156,0.023,0.233,0.036c0.267,0.044,0.53,0.102,0.789,0.177
	        c0.035,0.01,0.071,0.017,0.106,0.027c0.285,0.087,0.563,0.197,0.835,0.321c0.071,0.032,0.14,0.067,0.21,0.101
	        c0.24,0.119,0.475,0.249,0.702,0.396C21.719,20.373,23,22.538,23,25c0,0.553,0.447,1,1,1s1-0.447,1-1c0-2.754-1.246-5.219-3.2-6.871
	        C24.667,14.379,29.388,12,34.085,12c7.745,0,14.177,6.135,14.848,13.888c-1.022-0.072-2.552-0.109-4.083,0.124
	        c-0.546,0.083-0.921,0.593-0.838,1.139c0.075,0.495,0.501,0.85,0.987,0.85c0.05,0,0.101-0.004,0.151-0.012
	        c2.227-0.337,4.548-0.021,4.684-0.002C54.49,28.872,58,33.161,58,37.972C58,43.501,53.501,48,47.972,48z"
          />
        </g>
      </svg>
      <div className={'result'}>
        {success ? (
          <IconChecked size={resultSize} fill={Defaults.mainColor} />
        ) : (
          <IconUnchecked size={resultSize} fill={'#fd3d34'} />
        )}
      </div>
    </CloudIconBox>
  );
};

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  align-items: center;
  margin-top: auto;
  /* max-width: 9.5rem; */
  & > div:not(:first-child) {
    margin-left: 1rem;
    min-width: 7rem;
  }
`;
const ControlBox = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.direction ? props.direction : 'column')};
  flex-grow: ${(props) => props.grow || 0};
  gap: 6px;
  align-self: ${(props) => (props.alignSelf ? props.alignSelf : 'flex-end')};
  ${(props) => (props.direction === 'row' ? 'align-items: center' : '')};

  & div.control-box {
    margin-top: 0.6vh;
  }
`;
const CurrencyImgBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;

  &:active {
    background-color: #f5f5f5;
  }
`;
const ButtonBox = styled.div`
  display: flex;
  align-self: flex-end;
  ${(props) => (props.width ? `width: ${px(props.width)};` : '')}
`;
const PromocodeQuantityBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 6vh;
  min-width: 31.875rem;
`;
const CloudIconBox = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${(props) => px(props.top)};

  & .result {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: ${(props) => px(props.checkedOffset)};
    top: ${(props) => px(props.checkedOffset)};
  }
`;
