import { SvgIcon } from '@material-ui/core';
import { Helmet } from 'react-helmet-async';
import { Translation, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { setPage } from '../config/history';
import { BTC_URL, ETH_URL, USDT_URL } from '../helpers/settings';
import { formatString, openLink } from '../helpers/utils';
import { BTCROUNDED, ETHROUNDED, USDTROUNDED } from './currencies';

export const UNEXPECTED_ERROR = 'Unexpected error. Please contact administrator.';

export const MERCHANT_CONTROLS_HEIGHT = '3.75rem';

export const Defaults = {
  fontSize: `1rem`,
  height: '3.75rem',
  width: '3.75rem',
  padding: '.5rem',
  spacing: '1rem',
  textColor: '#2a2a31',
  activeColor: 'white',
  blackColor: 'black',
  hoverColor: '#f8f8f8',
  mainColor: '#00b3fb',
  grayColor: '#989da3',
  redColor: '#fd3d34',
  silverGray: '#8B959E',
  greenColor: '#00cd00',
  whiteColor: '#ffffff',
  neroColor: '#1D1D1D',
  inactiveColor: '#989da3',
  borderWidth: `2px`,
  borderRadius: `8px`,
  imgSize: '1.29rem',
  fontWeight: 400,
  fontFamily: '',
  transition: 'all .2s  linear',
  activatedColor: '#4CD964',
  aliceBlue: '#f3f6fa',
  lightBlue: '#f3f4f5',
  whiteSmoke: '#F1F1F180',
  periwinkleCrayola: '#F3F6FA',
  black24: '#00000024',
  cinnabar: '#EF3124',
};

export const transactionLinkMasks = {
  BTC: BTC_URL,
  ETH: ETH_URL,
  USDT: USDT_URL,
};

export const CURRENCIES_ICONS = {
  BTC: <BTCROUNDED />,
  ETH: <ETHROUNDED />,
  USDT: <USDTROUNDED />,
};

export const StyledPageBlocksContainer = styled.div`
  display: flex;
  gap: 32px;
  @media (max-width: 1670px) {
    flex-direction: column;
    gap: 40px;
  }
`;

export const StyledPageLeftBlock = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: 2px 3px 32px 0px rgba(168, 184, 200, 0.32);
  border-radius: 8px;
  background-color: ${Defaults.whiteColor};
  flex-grow: ${(props) => props.flexGrow || 0};
`;

export const StyledPageRightBlock = styled.div`
  min-width: 500px;

  @media (max-width: 1670px) {
    min-width: 100%;
  }
`;

export const Notification = styled.div`
  margin-bottom: 10px;
  opacity: 0;
  color: white;
  padding: 10px;
  font-size: 1.2em;
  transition: ${Defaults.transition};
  border-radius: 10px;
`;

export const NotificationsContainer = styled.div`
  position: fixed;
  bottom: 20px;
  z-index: 1;
  right: 20px;
  padding: 10px;
  @media (max-width: 768px) {
    bottom: 75px;
    left: 20px;
    right: unset;
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  box-shadow: 2px 3px 32px 0px rgba(168, 184, 200, 0.32);
`;

export const PageListContainer = styled.div`
  min-height: 13rem;
  background-color: ${(props) => props.backgroundColor || Defaults.whiteColor};

  #bids-table {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1.5rem;
    padding: 1.5rem;

    & > a {
      width: 100%;
    }
  }
`;

export const ContentRoot = styled.div`
  display: flex;
  @media (min-width: 768px) {
    margin-top: 2rem;
  }
  /* margin-top: 1.014rem; */

  flex-direction: column;
  height: 90%;
`;

export const ContentRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  ${(props) =>
    props.justifyContent ? 'justify-content: ' + props.justifyContent + ';' : ''}
  flex: ${(props) => props.flex || '0 1'};
  ${(props) => (!props.auto ? 'min-height: ' + px(props.height) + ';' : '')}
  margin-top: ${(props) => px(props.top || 0)};

  @media (min-width: ${(props) =>
      props.wrapBreakpoint ? props.wrapBreakpoint : '1100px'}) {
    flex-wrap: nowrap;
  }
`;
export const ContentColumn = styled.div`
  //display: flex;
  //flex-direction: column;
  flex: 0 0 100%;
  //width: ${(props) => px(props.width || 'auto')};

  @media (min-width: 1100px) {
    order: 10;
    margin-left: ${(props) => px(props.left)};
    max-width: ${(props) => px(props.width || '33%')};
  }
  order: -1;
  width: 100%;
  height: ${(props) => px(props.height || 'auto')};
`;
export const ContentRowBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  ${(props) => (props.minWidth ? `min-width: ${px(props.minWidth)};` : '')}
  padding: .86rem;
  background-color: white;
  border-radius: 8px;
  align-items: center;
`;
export const ContentRowItem = styled.div`
  display: flex;
  flex-grow: ${(props) => props.grow || 0};
  margin-left: ${(props) => px(props.left || 0)};
  margin-right: ${(props) => px(props.right || 0)};
`;
export const VDivider = styled(ContentRowItem)`
  width: 1px;
  height: 3.714rem;
  border-left: 1px solid #e9e9e9;
`;
export const ComponentRootEx = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  ${(props) => (props.maxWidth ? `max-width: ${props.maxWidth};` : '')}
  margin-left: ${(props) => px(props.left || 0)};
  margin-right: ${(props) => px(props.right || 0)};
  margin-top: ${(props) => px(props.top || 0)};
  margin-bottom: ${(props) => px(props.bottom || 0)};
  min-width: ${(props) => px(props.minWidth)};
  width: ${(props) => px(props.width)};
  ${(props) => (props.minHeight ? `min-height: ${px(props.minHeight)};` : '')}
  height: ${(props) => px(props.height || Defaults.height)};
  border: ${px(Defaults.borderWidth)} solid
    ${(p) => p.borderColor || p.backgroundColor || Defaults.activeColor};
  border-radius: ${(props) => px(props.borderRadius || Defaults.borderRadius)};
  /* font-family: ${(props) => props.fontFamily || Defaults.fontFamily}; */
  cursor: ${(props) => (props.cursor ? props.cursor : 'pointer')};
  user-select: none;
  transition: ${Defaults.transition};
  ${(props) =>
    props.backgroundColor ? `background-color: ${props.backgroundColor};` : ''}

  &:hover {
    background-color: ${(props) =>
      props.hoverColor || props.borderColor || Defaults.activeColor};
  }
  &.active {
    background-color: ${(props) =>
      props.hoverColor || props.borderColor || Defaults.activeColor};
  }
`;
export const PopoverEx = styled.div`
  position: absolute;
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
  @media (min-width: 768px) {
    right: auto;
  }
  right: 0;
  top: 100%;
  width: auto;
  height: auto;
  transform: scaleY(0);
  transform-origin: left top;
  transition: ${Defaults.transition};
  border-radius: ${(props) => px(props.borderRadius || Defaults.borderRadius)};
  background-color: ${(props) => props.backgroundColor || Defaults.activeColor};
  cursor: default;
  box-shadow: 0 1px 0.129rem 0 rgba(1, 1, 1, 0.1);

  &.opened {
    transform: scaleY(1);
  }
`;
export const TextEx = styled.span`
  color: ${(props) => props.color || Defaults.textColor};
  /* font-family: ${(props) => props.fontFamily || Defaults.fontFamily}; */
  font-size: ${(props) => px(props.size || Defaults.fontSize)};
  ${(props) => (props.weight ? `font-weight: ${props.weight};` : '')}
  ${(props) => (props.padding ? `padding: ${props.padding};` : '')}
  ${(props) => (props.letterSpacing ? `letter-spacing: ${px(props.letterSpacing)};` : '')}
  ${(props) => (props.uppercase ? 'text-transform: uppercase;' : '')}
  ${(props) => (props.lowercase ? 'text-transform: lowercase;' : '')}
  ${(props) => (props.capitalize ? 'text-transform: capitalize;' : '')}
  ${(props) => (props.textAlign ? `text-align: ${props.textAlign};` : '')}
  ${(props) => (props.top ? `margin-top: ${px(props.top)};` : '')}
  ${(props) => (props.bottom ? `margin-bottom: ${px(props.bottom)};` : '')}
  ${(props) => (props.left ? `margin-left: ${px(props.left)};` : '')}
  ${(props) => (props.right ? `margin-right: ${px(props.right)};` : '')}
  ${(props) => (props.height ? `height: ${px(props.height)};` : '')}
  ${(props) => (props.width ? `width: ${px(props.width)};` : '')}
  ${(props) => (props.minWidth ? `min-width: ${px(props.minWidth)};` : '')}
  ${(props) => (props.lineHeight ? `line-height: ${px(props.lineHeight)};` : '')}
  ${(props) => (props.customStyle ? props.customStyle : '')}
`;
export const Href = styled.a`
  color: ${(props) => props.color || Defaults.mainColor};
  font-size: ${(props) => px(props.size || Defaults.fontSize)};
  border-bottom: 1px solid transparent;
  transition: ${Defaults.transition};
  &:hover,
  &.active {
    color: ${Defaults.mainColor};
    border-bottom: 1px solid ${Defaults.mainColor};
    text-decoration: none;
  }
  ${(props) => (props.weight ? `font-weight: ${props.weight};` : '')}
  ${(props) => (props.letterSpacing ? `letter-spacing: ${px(props.letterSpacing)};` : '')}
    ${(props) => (props.uppercase ? 'text-transform: uppercase;' : '')}
    ${(props) => (props.lowercase ? 'text-transform: lowercase;' : '')}
    ${(props) => (props.capitalize ? 'text-transform: capitalize;' : '')}
    ${(props) => (props.textAlign ? `text-align: ${props.textAlign};` : '')}
    ${(props) => (props.top ? `margin-top: ${px(props.top)};` : '')}
    ${(props) => (props.left ? `margin-left: ${px(props.left)};` : '')}
    ${(props) => (props.height ? `height: ${px(props.height)};` : '')}
    ${(props) => (props.width ? `width: ${px(props.width)};` : '')}
    ${(props) => (props.minWidth ? `min-width: ${px(props.minWidth)};` : '')}
    ${(props) => (props.lineHeight ? `line-height: ${px(props.lineHeight)};` : '')}
    ${(props) => (props.customStyle ? props.customStyle : '')}
`;
export const DashedText = styled(TextEx)`
  color: ${Defaults.mainColor};
  cursor: pointer;
  border-bottom: 0.1vh dashed;
`;
export const Hr = styled.div`
  width: 100%;
  height: ${(props) => px(props.height || 0)};
  border-bottom: 1px solid #e9e9e9;
  flex-shrink: 0;
`;
export const Spacer = styled.div`
  ${(props) => (props.width ? `width: ${px(props.width)};` : '')}
  ${(props) => (props.height ? `height: ${px(props.height)};` : '')}
  ${(props) => (!props.width && !props.height ? 'flex-grow: 1;' : '')}
  ${(props) => (props.minWidth ? `min-width: ${px(props.minWidth)};` : '')}
`;
export const CloseButtonBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => px(props.size)};
  height: ${(props) => px(props.size)};
  border-radius: 50%;
  background-color: #f1f1f1;
  transition: ${Defaults.transition};
  cursor: pointer;

  &:hover {
    background-color: #e8e8e8;
  }
`;
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  ${(props) => props.wrap && 'flex-wrap: wrap;'}
  ${(props) => (props.noCenter ? '' : 'align-items: center;')}
  ${(props) => (props.justifyContent ? `justify-content: ${props.justifyContent};` : '')}
  ${(props) => (props.top ? `margin-top: ${px(props.top)};` : '')}
  ${(props) => (props.bottom ? `margin-bottom: ${px(props.bottom)};` : '')}
  ${(props) => (props.left ? `margin-left: ${px(props.left)};` : '')}
  ${(props) => (props.padding ? `padding: ${props.padding};` : '')}
  ${(props) => (props.width ? `width: ${px(props.width)};` : '')}
  ${(props) => (props.height ? `height: ${px(props.height)};` : '')}
  ${(props) => (props.customStyle ? props.customStyle : '')}
`;
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) => (props.top ? `margin-top: ${px(props.top)};` : '')}
  ${(props) => (props.left ? `margin-left: ${px(props.left)};` : '')}
  ${(props) => (props.right ? `margin-right: ${px(props.right)};` : '')}
  ${(props) => (props.width ? `width: ${px(props.width)};` : '')}
  ${(props) => (props.minWidth ? `min-width: ${px(props.minWidth)};` : '')}
  ${(props) => (props.minHeight ? `min-height: ${px(props.minHeight)};` : '')}
  ${(props) => (props.padding ? `padding: ${props.padding};` : '')}
  ${(props) => (props.alignItems ? `align-items: ${props.alignItems};` : '')}
  ${(props) => (props.flexGrow ? `flex-grow: ${props.flexGrow};` : '')}
  ${(props) => (props.customStyle ? props.customStyle : '')}
`;
export const ClipText = styled.div`
  max-width: ${(props) => px(props.width)};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    text-overflow: clip;
    white-space: normal;
    word-break: break-all;
  }
`;
export const Img = styled.img`
  ${(props) => (props.left ? `margin-left: ${px(props.left)};` : '')}
  ${(props) => (props.top ? `margin-top: ${px(props.top)};` : '')}
    ${(props) => (props.width ? `width: ${px(props.width)};` : '')}
    ${(props) => (props.height ? `height: ${px(props.height)};` : '')}
    ${(props) => (props.maxWidth ? `max-width: ${px(props.maxWidth)};` : '')}
    ${(props) => (props.maxHeight ? `max-height: ${px(props.maxHeight)};` : '')}
    ${(props) => (props.borderColor ? `border: 1px solid ${props.borderColor};` : '')}
    ${(props) => (props.padding ? `padding: ${props.padding};` : '')}
    border-radius: .129rem;
`;

export const Hyperlink = ({ url, text }: { url?; text: string }) => (
  <TextEx
    customStyle={'text-decoration: underline; cursor: pointer;'}
    color={Defaults.mainColor}
    onClick={() => openLink(url || text)}>
    {text}
  </TextEx>
);

export const PageTitle = ({
  title,
  args = [],
  padding,
}: {
  title: string;
  args?: any[];
  padding?: string | number;
}) => {
  const { t } = useTranslation();
  return (
    <TextEx
      className={'PageTitle'}
      size={'2rem'}
      letterSpacing={'0.28em'}
      customStyle={
        'display: block;' + ' padding-bottom: 1.2rem;' + ' font-weight: 500' + ' '
      }
      padding={padding}
      uppercase>
      <div dangerouslySetInnerHTML={{ __html: formatString(t(title), ...args) || '' }} />
    </TextEx>
  );
};

export const HelmetMetaTags = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Купить биткоин онлайн через обменник SKY CRYPTO</title>
      <meta
        name="description"
        content="SKY CRYPTO - сервис для работы с криптовалютой , с функциями: P2P-маркетплейс , платёжный шлюз SKY PAY и кошелек для безопасного хранения криптовалюты."
      />
      <link rel="shortcut icon" href="../../public/favicon.ico" />
    </Helmet>
  </>
);

export const Timer = ({
  time,
  top,
  left,
  fontSize = '1.4rem',
  hint,
  noIcon = false,
}: {
  time;
  top?;
  left?;
  fontSize?: number | string;
  hint?: string;
  noIcon?: boolean;
}) => {
  const { t } = useTranslation();
  const tick = time % 2 === 0;
  const m = time ? Math.floor(time / 60) : 0;
  const s = time ? time - m * 60 : 0;

  return (
    <Row top={top} left={left} title={hint ? t(hint) : {}}>
      {!noIcon ? <ClockIcon size={'2.714rem'} fill={Defaults.grayColor} /> : undefined}
      <TextEx
        weight={'500'}
        size={fontSize}
        left={'0.6vh'}
        width={'2.143rem'}
        textAlign={'right'}>
        {String(m).padStart(2, '0')}
      </TextEx>
      <TextEx
        weight={'500'}
        size={fontSize}
        color={tick ? 'transparent' : undefined}
        width={'.8125rem'}
        textAlign={'center'}>
        :
      </TextEx>
      <TextEx weight={'500'} size={fontSize} width={'2.143rem'} textAlign={'left'}>
        {String(s).padStart(2, '0')}
      </TextEx>
    </Row>
  );
};

export const CloseButton = (props) => {
  const { t } = useTranslation();
  return (
    <div
      title={props.hint ? t(props.hint) : undefined}
      className={props.className}
      style={{
        cursor: 'pointer',
        ...props,
        zIndex: 2,
      }}
      onClick={(event) => {
        event.stopPropagation();
        if (props.onClick) {
          props.onClick();
        }
      }}>
      {props.children ?? (
        <SvgIcon
          viewBox={'0 0 18 18'}
          style={{
            width: props.size || '1.125rem',
            height: props.size || '1.125rem',
            fill: props.fill || '#878a90',
            zIndex: 1,
          }}>
          <g>
            <path
              d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47
                4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"
            />
          </g>
        </SvgIcon>
      )}
    </div>
  );
};

export const ClockIcon = ({ size, fill }: { size: number | string; fill: string }) => (
  <svg style={{ width: size, height: size }} viewBox={'0 0 24 24'} fill={'none'}>
    <path
      stroke={fill}
      strokeWidth={2}
      d={'M12,21c-5,0-9-4-9-9s4-9,9-9s9,4,9,9S17,21,12,21z'}
    />
    <polyline stroke={fill} strokeWidth={2} points={'12,7 12,12 15,15'} />
  </svg>
);

export const WarnIcon = ({ size, fill }: { size: number | string; fill: string }) => (
  <svg style={{ width: size, height: size }} viewBox={'0 0 512 512'} fill={fill}>
    <path
      d="M505.403,406.394L295.389,58.102c-8.274-13.721-23.367-22.245-39.39-22.245c-16.023,0-31.116,8.524-39.391,22.246
			L6.595,406.394c-8.551,14.182-8.804,31.95-0.661,46.37c8.145,14.42,23.491,23.378,40.051,23.378h420.028
			c16.56,0,31.907-8.958,40.052-23.379C514.208,438.342,513.955,420.574,505.403,406.394z M477.039,436.372
			c-2.242,3.969-6.467,6.436-11.026,6.436H45.985c-4.559,0-8.784-2.466-11.025-6.435c-2.242-3.97-2.172-8.862,0.181-12.765
			L245.156,75.316c2.278-3.777,6.433-6.124,10.844-6.124c4.41,0,8.565,2.347,10.843,6.124l210.013,348.292
			C479.211,427.512,479.281,432.403,477.039,436.372z"
    />
    <path
      d="M256.154,173.005c-12.68,0-22.576,6.804-22.576,18.866c0,36.802,4.329,89.686,4.329,126.489
			c0.001,9.587,8.352,13.607,18.248,13.607c7.422,0,17.937-4.02,17.937-13.607c0-36.802,4.329-89.686,4.329-126.489
			C278.421,179.81,268.216,173.005,256.154,173.005z"
    />
    <path
      d="M256.465,353.306c-13.607,0-23.814,10.824-23.814,23.814c0,12.68,10.206,23.814,23.814,23.814
			c12.68,0,23.505-11.134,23.505-23.814C279.97,364.13,269.144,353.306,256.465,353.306z"
    />
  </svg>
);

export const YesIcon = ({ size, fill }: { size: number | string; fill?: string }) => (
  <svg style={{ width: size, height: size, fill }} viewBox={'0 0 512 512'}>
    <polygon points="211.344,306.703 160,256 128,288 211.414,368 384,176 351.703,144 	" />
    <path
      d="M256,0C114.609,0,0,114.609,0,256c0,141.391,114.609,256,256,256c141.391,0,256-114.609,256-256
		    C512,114.609,397.391,0,256,0z M256,472c-119.297,0-216-96.703-216-216S136.703,40,256,40s216,96.703,216,
		    216S375.297,472,256,472z"
    />
  </svg>
);

export const NoIcon = ({ size, fill }: { size: number | string; fill?: string }) => (
  <svg style={{ width: size, height: size, fill }} viewBox={'0 0 65 65'}>
    <path
      d="M32.759,0C14.696,0,0,14.695,0,32.759s14.695,32.759,32.759,32.759s32.759-14.695,32.759-32.759S50.822,0,
            32.759,0z  M6,32.759C6,18.004,18.004,6,32.759,6c6.648,0,12.734,2.443,17.419,6.472L12.472,50.178C8.443,
            45.493,6,39.407,6,32.759z  M32.759,59.518c-5.948,0-11.447-1.953-15.895-5.248l37.405-37.405c3.295,4.448,
            5.248,9.947,5.248,15.895 C59.518,47.514,47.514,59.518,32.759,59.518z"
    />
  </svg>
);

export const Logout = ({ color }: { color?: string }) => (
  <svg
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.34265 3C7.88838 1.7492 9.85669 1 12 1C16.9705 1 21 5.02944 21 10C21 14.9706 16.9705 19 12 19C9.85669 19 7.88838 18.2508 6.34265 17M12 10H1M1 10L4 7M1 10L4 13"
      stroke={color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const PendingIcon = ({ size }: { size: number | string }) => (
  <svg
    style={{ width: size, height: size }}
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    fill="none">
    <path
      stroke="#000000"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 6.5V12l3.5 2m5.5-2a9 9 0 11-18 0 9 9 0 0118 0z"
    />
  </svg>
);

export const OpenNewTabIcon = ({ className }: { className?: string }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}>
    <path
      d="M11 3L4 3C3.44772 3 3 3.44772 3 4V20C3 20.5523 3.44772 21 4 21H20C20.5523 21 21 20.5523 21 20V13M12 12L21 3M21 3V10M21 3H14"
      stroke="black"
      strokeWidth="1.5"
    />
  </svg>
);

export const DeleteIcon = ({ size = 24 }: { size?: number | string }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="#EF3124" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.0303 8.03033C17.3232 7.73744 17.3232 7.26256 17.0303 6.96967C16.7374 6.67678 16.2626 6.67678 15.9697 6.96967L12 10.9393L8.03033 6.96967C7.73744 6.67678 7.26256 6.67678 6.96967 6.96967C6.67678 7.26256 6.67678 7.73744 6.96967 8.03033L10.9393 12L6.96967 15.9697C6.67678 16.2626 6.67678 16.7374 6.96967 17.0303C7.26256 17.3232 7.73744 17.3232 8.03033 17.0303L12 13.0607L15.9697 17.0303C16.2626 17.3232 16.7374 17.3232 17.0303 17.0303C17.3232 16.7374 17.3232 16.2626 17.0303 15.9697L13.0607 12L17.0303 8.03033Z"
      fill="white"
    />
  </svg>
);

export const LinkEx = (props) => (
  <Href
    {...props}
    href={props.href}
    target={props.blank ? '_blank' : undefined}
    rel={props.blank ? 'noreferrer' : undefined}
    onClick={(event) => {
      if (!props.external) {
        event.preventDefault();
        if (props.onClick) {
          props.onClick();
        } else if (props.href) {
          const page = props.href[0] === '/' ? props.href.substr(1) : props.href;
          setPage(page);
        }
      }
    }}>
    {props.children}
  </Href>
);

export const UserLink = ({
  user,
  size = '1rem',
  left,
  top,
  prefix,
  uppercase,
  width,
}: {
  user: string;
  size?;
  left?;
  top?: number | string;
  prefix?: string;
  uppercase?: boolean;
  width?: number;
}) => (
  <LinkEx
    href={`/users/${user}`}
    size={size}
    left={left}
    top={top}
    uppercase={uppercase}
    width={width}>
    {prefix}
    {`/u${user}`}
  </LinkEx>
);

export const HtmlText = (props) => (
  <Translation>
    {(txt) => (
      <TextEx {...props}>
        <div
          dangerouslySetInnerHTML={{
            __html: formatString(txt(props.k), ...(props.args ? props.args : [])) || '',
          }}
        />
      </TextEx>
    )}
  </Translation>
);

export function onClickOutside(element: HTMLElement, cb: () => void) {
  const outsideClickListener = (event) => {
    if (!hasContainer(event.target, element) && isVisible(element)) {
      cb();
      document.removeEventListener('click', outsideClickListener);
    }
  };

  document.addEventListener('click', outsideClickListener);
}

export function px(n?: any): string | undefined {
  return !!n && typeof n === 'number' ? n + 'px' : n;
}

export function calcSize(
  size: number | string | undefined,
  f: (size: number) => number,
): number | string | undefined {
  if (size === undefined) {
    return undefined;
  }
  if (typeof size === 'string') {
    const n = size.match(/([\d.]*)(.*)/);
    if (!n || n.length < 3) {
      return size;
    }
    return f(parseFloat(n[1])) + n[2];
  } else {
    return f(size);
  }
}

export function blink3(f: (blink: boolean) => void, timeout = 400, cb?: () => void) {
  f(true);
  setTimeout(() => f(false), timeout);
  setTimeout(() => f(true), 2 * timeout);
  setTimeout(() => f(false), 3 * timeout);
  setTimeout(() => f(true), 4 * timeout);
  setTimeout(() => f(false), 5 * timeout);
  cb && setTimeout(() => cb(), 6 * timeout);
}

export function changeClass(selector: string, className: string, add: boolean) {
  const el = document.querySelector(selector);
  if (!el) {
    return;
  }
  if (add) {
    el.classList.add(className);
  } else {
    el.classList.remove(className);
  }
}

function hasContainer(element: HTMLElement, container: HTMLElement): boolean {
  while (element.parentElement) {
    if (element === container) {
      return true;
    }
    element = element.parentElement;
  }
  return false;
}

function isVisible(element) {
  return (
    !!element &&
    !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length)
  );
}
