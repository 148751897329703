export const ACCEPTED_IMAGES_MIME_TYPES =
  'image/jpg, image/jpeg, image/png, image/ico, image/bmp, image/raw, image/tiff, image/psd, application/pdf';

export const WRONG_AMOUNT_ERROR = 'wrong amount';
export const WRONG_BID_RATE = 'Wrong bid rate';
export const CPAYMENT_WRONG_AMOUNT_ERROR = 'bad amount_currency';

export const PRIVACY_LINK = 'https://telegra.ph/Privacy-policy-04-10-40';

export const CURRENCY_LIMITS = {
  purchase: {
    rub: {
      min: '1000',
      max: '150000',
    },
    usd: {
      min: '8',
      max: '2200',
    },
    kzt: {
      min: '1000',
      max: '1000000',
    },
    uah: {
      min: '250',
      max: '75000',
    },
    byn: {
      min: '20',
      max: '10000',
    },
    tjs: {
      min: '70',
      max: '7000',
    },
    azn: {
      min: '10',
      max: '2000',
    },
    uzs: {
      min: '50000',
      max: '8000000',
    },
  },
  sale: {
    rub: {
      min: '3000',
      max: '150000',
    },
    usd: {
      min: '30',
      max: '2200',
    },
    kzt: {
      min: '10000',
      max: '1000000',
    },
    uah: {
      min: '250',
      max: '75000',
    },
    byn: {
      min: '40',
      max: '10000',
    },
    tjs: {
      min: '140',
      max: '70000',
    },
    azn: {
      min: '20',
      max: '2000',
    },
    uzs: {
      min: '100000',
      max: '8000000',
    },
  },
  cpayment: {
    rub: {
      min: '500',
      max: '150000',
    },
    usd: {
      min: '8',
      max: '2200',
    },
    kzt: {
      min: '5000',
      max: '1000000',
    },
    uah: {
      min: '500',
      max: '1000000',
    },
    byn: {
      min: '40',
      max: '1000000',
    },
    tjs: {
      min: '70',
      max: '7000',
    },
    azn: {
      min: '10',
      max: '2000',
    },
    uzs: {
      min: '50000',
      max: '8000000',
    },
  },
};
