import { isNil } from 'lodash';

import { isTabActive } from '../App';
import { isAllowedCrypto } from '../components/currencies';
import { UpdaterSettingsDefault } from '../helpers/settings';
import { findObjectInList } from '../helpers/utils';
import CurrenciesService from '../services/CurrenciesService';
import UsersService from '../services/UsersService';
import { CurrencyStore } from '../store';
import { Rate, SubRate } from './types';

export function classNames(...args): string {
  return args.filter((className) => className).join(' ');
}

export const formData = (params: Record<string, any>): FormData => {
  const data = new FormData();
  for (const name in params) {
    !isNil(params[name]) && data.append(name, params[name]);
  }
  return data;
};

export function updater(
  update: () => void,
  interval = UpdaterSettingsDefault.generalUpdaterTime,
  straight = true,
): () => void {
  if (straight) {
    isTabActive && update();
  }

  const handleVisibilityChange = () => {
    document.visibilityState === 'visible' && update();
  };
  document.addEventListener('visibilitychange', handleVisibilityChange);

  const handler = () => {
    isTabActive && update();
  };

  const intervalId = setInterval(handler, interval);

  return () => {
    clearInterval(intervalId);
    document.removeEventListener('visibilitychange', handleVisibilityChange);
  };
}

function filterRates(rates: SubRate[]): SubRate[] {
  const filtered: SubRate[] = [];
  for (const rate of rates) {
    if (CurrencyStore.fiatCurrenciesList.includes(rate.currency.toUpperCase())) {
      filtered.push({ currency: rate.currency.toUpperCase(), rate: rate.rate });
    }
  }

  return filtered;
}

export function getCryptoRates(): Promise<Rate[]> {
  return new Promise<Rate[]>((resolve) => {
    CurrenciesService.getRates()
      .then((records) => {
        if (records) {
          const cryptos = {};
          for (const record of records) {
            if (!isAllowedCrypto(record.symbol.toUpperCase())) {
              continue;
            }
            if (!cryptos?.[record.symbol]) {
              cryptos[record.symbol] = [];
            }
            cryptos[record.symbol].push({ currency: record.currency, rate: record.rate });
          }
          const rates: Rate[] = [];
          for (const crypto in cryptos) {
            cryptos[crypto] &&
              rates.push({
                currency: crypto.toUpperCase(),
                rates: filterRates(cryptos[crypto]),
              });
          }
          resolve(rates);
        } else {
          resolve([]);
        }
      })
      .catch(() => undefined);
  });
}

export function getSubRate(rates: Rate[], crypto, currency: string): number {
  const rate = findObjectInList(rates, 'currency', crypto);
  const subRate = rate
    ? findObjectInList(rate.rates, 'currency', currency.toUpperCase())
    : undefined;
  return subRate ? subRate.rate : 0;
}

const externalRates: Rate[] = [];

export function getExternalRates(): Promise<Rate[]> {
  return new Promise((resolve) => {
    if (externalRates.length > 0) {
      resolve(externalRates);
      return;
    }

    const cryptos = {
      bitcoin: 'BTC',
      ethereum: 'ETH',
    };
    const currencies = ['rub', 'usd'];

    const ids = Object.keys(cryptos).join(',');
    fetch(
      `https://api.coingecko.com/api/v3/simple/price?ids=${ids}&vs_currencies=${currencies.join(
        ',',
      )}`,
    )
      .then((response: Response) => {
        if (response) {
          response.json().then((res) => {
            for (const crypto of Object.keys(res)) {
              const c = res[crypto];
              const subRates: SubRate[] = [];
              for (const currency of Object.keys(c)) {
                subRates.push({ currency: currency.toUpperCase(), rate: c[currency] });
              }
              if (!findObjectInList(externalRates, 'currency', cryptos[crypto])) {
                externalRates.push({ currency: cryptos[crypto], rates: subRates });
              }
            }
            resolve(externalRates);
          });
        }
      })
      .catch(() => resolve([]));
  });
}

export async function changeCurrency(currency: string): Promise<boolean> {
  await UsersService.changeCurrency({ currency: currency.toLowerCase() });
  return true;
}

export const getAddress = async () => {
  try {
    const token = process.env.REACT_APP_ADDRESS_USER_TOKEN;
    const response = await fetch(`https://ipinfo.io/json?token=${token}`);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error);
  }
};
